import React, { Component } from 'react';
import axios from 'axios';
import ProgressMobileStepper from './ProgressMobileStepper';
import {
    Typography,
    CardMedia,
    Card,
    CardActions
} from '@material-ui/core';
import { labels } from 'src/labels';
import label from 'src/utils/label';
import Box from '@material-ui/core/Box'
import TopBar from 'src/layouts/MainLayout/TopBar';
import CustomizedSnackbar from 'src/components/CustomizedSnackbar';

const API_URL = process.env.REACT_APP_API_BASE_URL;

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            surveyToken: props.token,
            lang: props.lang,
            surveyId: -1,
            languages: [],
            questions: [],
            answers: [],
            logics: {},
            ro: props.ro,
            pv: props.pv,
            status: '',
            matrixSurvey: false,
            snackBarOpen: false,
            snackBarSeverity: 'success',
            snackBarMessage: '',
            snackBarAutoHideDuration: 5000,
            activeStep:0,
            varMap: new Map(),
            isValid: true,
            loading: false,
            translateX: 0,
            opacity: 1
        };
        this.getActiveStep = this.getActiveStep.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.getComments = this.getComments.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.handleTerminateConfig = this.handleTerminateConfig.bind(this);
        this.handleRegisterConfig = this.handleRegisterConfig.bind(this);
        this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    }

    componentDidMount() {
        console.log("Calling componentDidMount...");
        this.fetchSurvey();
    }

    getData(v, i) {
        if (v)
            return parseInt(v.split('_')[i]);
    }

    getComments(questionId, answerId) {
        let answers = this.state.answers;
        const index = answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
        return (index >= 0) ? answers[index].comments : '';
    }

    isChecked(questionId, answerId) {
        let answers = this.state.answers;
        const checked = answers.some(item => item.questionId === questionId && item.answerId === answerId);
        if(questionId === 1725 && answerId !== 9319 && answers.some(item => item.questionId === questionId && item.answerId === 9319) ){
            return false;
        }
        if(questionId === 1726 && answerId !== 9324 && answers.some(item => item.questionId === questionId && item.answerId === 9324) ){
            return false;
        }
        return checked;
    }

    shuffleArray(array, startingIndex, fixedIndex, endingIndex) {
        for (let currentIndex = startingIndex; currentIndex < endingIndex; currentIndex++) {
            let randomIndex = startingIndex + (Math.floor(Math.random() * (endingIndex - startingIndex)));
            if (fixedIndex === currentIndex || fixedIndex === randomIndex) continue;
            if (currentIndex > endingIndex || randomIndex > endingIndex) continue;
            let tempValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = tempValue;
        }
        return array;
    }

    fetchSurvey = async () => {
        try {
            await axios.get(API_URL + "/api/survey?token=" + this.state.token + "&ro=" + this.state.ro + "&pv=" + this.state.pv)
                .then(response => {
                    if (response.data) {
                        let questionsResponse = response.data.questions;
                        let surveyId = questionsResponse && questionsResponse.length > 0 ? questionsResponse[0].surveyId : -1;
                        let foundGroup = questionsResponse ? questionsResponse.some( q => q.group && q.group.id) : false;
                        if (!!questionsResponse) questionsResponse.filter(qs => qs.type === 'CHECKBOX').map(q => { q.answers = q.answers.sort((a, b) => (a.id > b.id) ? 1 : -1); return this.shuffleArray(q.answers, 0, q.answers.length - 1, q.answers.length - 1) })
                        
                        let registerSurveys = [126,125,122,123,121];
                        let _registerConfigs = {
                            122: [{questionIndex: 2, otherQuestionIndex: 1, registerVar: "most_used_brand" }],
                            123: [{questionIndex: 2, otherQuestionIndex: 1, registerVar: "main_bank" }],
                            125: [{questionIndex: 2, otherQuestionIndex: 1, registerVar: "main_bank" }],
                            126: [{questionIndex: 2, otherQuestionIndex: 1, registerVar: "main_bank" }],
                            121: [{questionIndex: 2, otherQuestionIndex: 1, registerVar: "main_bank" },{questionIndex: 4, otherQuestionIndex: 4, registerVar: "service_provider" }]
                        }

                        // register config
                        if (registerSurveys.includes(surveyId) && _registerConfigs[surveyId]) {
                            _registerConfigs[surveyId].forEach(rc => {
                                let registerConfigQuestionIndex = rc.questionIndex;
                                let otherQuestionIndex = rc.otherQuestionIndex;
                                let registerVar = rc.registerVar;
                                let otherQuestionId = questionsResponse[otherQuestionIndex].id;
                                let registerConfig = { "registerVar": registerVar, "otherQuestionId": otherQuestionId };
                                questionsResponse[registerConfigQuestionIndex].registerConfig = registerConfig;
                            })
                        }

                        // filter config
                        if (response.data.logic && response.data.logic.filters && response.data.logic.filters.length > 0) {
                            response.data.logic.filters.forEach(fc => {
                                let question = questionsResponse.find(q => q.id === fc.questionId)
                                let filterConfig = { "sourceQuestionId": fc.sourceQuestionId };
                                question.filterConfig = filterConfig;
                            })
                        }

                        // display config
                        if (response.data.logic && response.data.logic.displays && response.data.logic.displays.length > 0) {
                            response.data.logic.displays.forEach(dc => {
                                let question = questionsResponse.find(q => q.id === dc.questionId)
                                let displayConfig = { "sourceQuestionId": dc.sourceQuestionId, "sourceAnswerIds": dc.sourceAnswerIds };
                                question.displayConfig = displayConfig;
                            })
                        }

                        // force response config
                        if (response.data.logic && response.data.logic.forceResponses && response.data.logic.forceResponses.length > 0) {
                            response.data.logic.forceResponses.forEach(fc => {
                                let question = questionsResponse.find(q => q.id === fc.questionId)
                                let forceResponsesConfig = { "sourceQuestionId": fc.sourceQuestionId, "sourceAnswerIds": fc.sourceAnswerIds };
                                question.forceResponsesConfig = forceResponsesConfig;
                            })
                        }

                        // terminate config
                        if (response.data.logic && response.data.logic.terminates && response.data.logic.terminates.length > 0) {
                            response.data.logic.terminates.forEach(tc => {
                                let question = questionsResponse.find(q => q.id === tc.questionId)
                                let terminateConfig = { "questionId": tc.questionId, "answerIds": tc.answerIds };
                                question.terminateConfig = terminateConfig;
                            })
                        }

                        this.setState({ matrixSurvey: foundGroup, surveyId: surveyId, logics: response.data.logic , token: response.data.token, status: response.data.status, questions: questionsResponse, answers: response.data.answers ? response.data.answers : [], languages: response.data.languages })
                    }
                }).catch(error => {
                    console.log(JSON.stringify(error));
                    this.setState({ status: 'NOTFOUND' })
                });

        } catch (err) {
            console.error(err);
        }
    }

    handleTerminateConfig = (questionId) => {
        let terminateLogic = this.state.logics && this.state.logics.terminates && this.state.logics.terminates.length > 0 && this.state.logics.terminates.find(t => t.questionId === questionId);
        if (terminateLogic) {
            let answer = this.state.answers.find(item => item.questionId === questionId && terminateLogic.answerIds.includes(item.answerId));
            console.log("answer: ", answer);
            if (answer) {
                return true;
            }
        }
        return false;
    }

    handleRegisterConfig = (questionId) => {
        let question = this.state.questions.find(q => q.id === questionId && q.registerConfig);
        if (question) {
            let registerConfig = question.registerConfig;
            let varKey = registerConfig.registerVar;
            let answer = this.state.answers.find(item => item.questionId === questionId);
            let selectedOtherAnswer = this.state.answers.find(item => item.questionId === questionId && item.comments !== "");
            let selectedAnswer = question.answers.find(item => item.questionId === questionId && item.id === answer.answerId);
            if (selectedOtherAnswer) {
                this.state.varMap.set(varKey, label({ "ar": selectedOtherAnswer.comments, "en": selectedOtherAnswer.comments }));
                this.setState({ varMap: this.state.varMap });
            } else if (selectedAnswer) {
                this.state.varMap.set(varKey, label(selectedAnswer.label));
                this.setState({ varMap: this.state.varMap });
            } else {
                let other = this.getComments(registerConfig.otherQuestionId, answer.answerId);
                this.state.varMap.set(varKey, other);
                this.setState({ varMap: this.state.varMap });
            }
        }
    }

    getActiveStep = (prevActiveStep, isNext) => {
        let prevQuestionId = this.state.questions[prevActiveStep].id;
        if (isNext && this.handleTerminateConfig(prevQuestionId)) {
            // Terminate and go to last page
            this.setState({ terminate: true })
            return this.state.questions.length - 1;
        }
        let activeStep = isNext ? prevActiveStep + 1 : prevActiveStep - 1;
        let question = this.state.questions[activeStep];
        if(!isNext && question && question.group && question.group.id) {
            let matrixLen = this.state.questions.filter(q => q.group && q.group.id === question.group.id).length - 1
            activeStep = activeStep- matrixLen;
            question = this.state.questions[activeStep];
        }
        let displayLogic = this.state.logics && this.state.logics.displays && this.state.logics.displays.length > 0 && this.state.logics.displays.find(t => t.questionId === question.id && !question.group);
        if (!!displayLogic) {
            let sourceQuestionId = displayLogic.sourceQuestionId;
            let sourceAnswerIds = displayLogic.sourceAnswerIds;
            let answers = this.state.answers;
            let isMatch = false;
            sourceAnswerIds.forEach(function (sourceAnswerId) {
                if (isMatch) return;
                isMatch = answers.some(item => item.questionId === sourceQuestionId && item.answerId === parseInt(sourceAnswerId));
            });
            console.log('checkActiveStep::isMatch: ' + isMatch);
            if (!isMatch) {
                return this.getActiveStep(activeStep, isNext);
            }
        }
        return activeStep;
    }

    setActiveStep(activeStep) {
        this.setState({ activeStep: activeStep });
    }

    setIsValid(isValid) {
        this.setState({ isValid: isValid });
    }

    setLoading(loading) {
        this.setState({ loading: loading });
    }

    validate = (questionId, isRequired) => {
        if (this.state.ro || !isRequired)
            return true;
        let answers = this.state.answers;
        let isValid = answers.some(item => item.questionId === questionId);
        if(isValid) {
            let answer = answers.filter(item => item.questionId === questionId)
            if(answer.length > 1) {
                return true;
            } else {
                let isCommentsRequired = this.state.questions.find(q=> q.id === questionId).answers.find(a => a.id === answer[0].answerId).comments
                isValid = isCommentsRequired && answer[0].comments.length === 0 ? false : true
            }
        }
        if (!isValid)
            window.scrollTo(0, 0);
        return isValid;
    }

    handleSubmit = async (questionId, isCompleted) => {
        try {
            if (this.state.ro || this.state.pv){
                this.setLoading(false);
                this.handleRegisterConfig(questionId);
                this.setState((prevState, props) => ({activeStep: this.getActiveStep(prevState.activeStep, true), ...prevState.items}))
                return;
            }
            // return;
            this.handleRegisterConfig(questionId);
            await axios.post(API_URL + "/api/survey/response", { "token": this.state.token.split('&')[0], "questionId": questionId, "isCompleted": isCompleted, "answers": this.state.answers })
                .then(response => {
                    if (response.data) {
                        this.setState({ loading: false });
                        this.setState((prevState, props) => ({activeStep: this.getActiveStep(prevState.activeStep, true), ...prevState.items}))
                    }
                }).catch(error => {
                    console.log(JSON.stringify(error))
                    console.log(JSON.stringify(error));
                    this.setState({ snackBarOpen: true, snackBarSeverity: 'error', snackBarMessage: label(labels.get("click_next_again"))});
                });
        } catch (err) {
            console.error(err);
            this.setState({ snackBarOpen: true, snackBarSeverity: 'error', snackBarMessage: label(labels.get("click_next_again"))});
        } finally {
            this.setState({ loading: false });
        }
    }

    handleMatrixSubmit = async (isCompleted, length, questionsList) => {
        try {
            await Promise.all([
                questionsList.forEach((q, index) => {
                    if (this.state.ro || this.state.pv){
                        this.setLoading(false);
                        this.handleRegisterConfig(q.id);
                        this.setState((prevState, props) => ({activeStep: this.getActiveStep(prevState.activeStep, true), ...prevState.items}))
                        return;
                    }
                    this.handleRegisterConfig(q.id);
                    axios.post(API_URL + "/api/survey/response", { "token": this.state.token.split('&')[0], "questionId": q, "isCompleted": isCompleted, "answers": this.state.answers })
                    .then(response => {
                        if (response.data) {
                            if(index === questionsList.length -1){
                                this.setLoading(false);
                                this.setState((prevState, props) => ({activeStep: this.getActiveStep(length-1, true), ...prevState.items}))
                            }
                        }
                    }).catch(error => {
                        console.log(JSON.stringify(error))
                        this.setState({ snackBarOpen: true, snackBarSeverity: 'error', snackBarMessage: label(labels.get("click_next_again"))});
                    });
                })
            ])
        } catch (err) {
            console.error(err);
            this.setState({ snackBarOpen: true, snackBarSeverity: 'error', snackBarMessage: label(labels.get("click_next_again"))});
        } finally {
            this.setLoading(false);
        }
    }

    handleSnackBarClose = () => {
        this.setState({ snackBarOpen: false});
    };

    OnTransform (val, op) {
        this.setState({
            translateX: val,
            opacity: op
        })
        if(val ===-100) setTimeout(() => {  this.OnTransform(+100, 0); }, 500);
    }

    handleNext = () => {
        let isCompleted = (this.state.activeStep === this.state.questions.length - 2) ? true : (this.getActiveStep(this.state.activeStep, true) === this.state.questions.length - 1) ? true : false;
        let questionId = this.state.questions[this.state.activeStep].id;
        let isRequired = this.state.questions[this.state.activeStep].required;
        let forceLogicEnabled = this.state.questions[this.state.activeStep].forceResponsesConfig;
        if(!!forceLogicEnabled) {
          let sourceQuestion = forceLogicEnabled.sourceQuestionId;
          let sourceAnswerIds = forceLogicEnabled.sourceAnswerIds;
          console.log(this.state.answers, sourceAnswerIds, sourceQuestion)
          let foundAnswers = this.state.answers.filter(item => item.questionId === sourceQuestion && sourceAnswerIds.includes(item.answerId))
          isRequired = foundAnswers && foundAnswers.length > 0 ? true : false;
        }
        if (this.validate(questionId, isRequired)) {
          this.setIsValid(true);
          this.setLoading(true);
          this.handleSubmit(questionId, isCompleted);
        } else {
          this.setIsValid(false);
        }
      };
    
    handleMatrixNext = (matrix) => {
        let questionsList = matrix.questions.map(q => q.id);
        let isCompleted = (this.state.activeStep === this.state.questions.length - 2) ? true : false;
        let questionId = this.state.questions[this.state.activeStep].id;
        let isRequired = this.state.questions[this.state.activeStep].required;
        if(questionsList && questionsList.includes(questionId)){
            let isTerminate = false;
            //skip to display question inside matrix
            let foundDisplayLogics = matrix.questions.filter(q => !!q.displayConfig)
            if(!!foundDisplayLogics && foundDisplayLogics.length > 0) {
                foundDisplayLogics.forEach(dl => {
                    let sourceQuestionId = dl.displayConfig.sourceQuestionId;
                    let sourceAnswerIds = dl.displayConfig.sourceAnswerIds;
                    let destinationStepNo = matrix.questions.find(q => q.id === dl.id).groupStepNo;
                    let sourceStepNo = matrix.questions.find(q => q.id === sourceQuestionId).groupStepNo;
                    let isMatch = false;
                    let answers = this.state.answers;
                    sourceAnswerIds.forEach(function (sourceAnswerId) {
                        isMatch = answers.some(item => item.questionId === sourceQuestionId && item.answerId === parseInt(sourceAnswerId));
                        if(isMatch) {
                            matrix.questions.forEach(mq => {
                                if(mq.groupStepNo < destinationStepNo && mq.groupStepNo > sourceStepNo) {
                                    mq.required = false;
                                } else {
                                    mq.required = true;
                                }
                            })
                        } else {
                            matrix.questions.forEach(mq => {
                                if(mq.groupStepNo === destinationStepNo) {
                                    mq.required = false;
                                }
                            })
                        }
                    });
                })
            }
            let result = matrix.questions.map(q => {
            isRequired = q.required;
            let forceLogicEnabled = q.forceResponsesConfig;
            let terminateLogicEnabled = q.terminateConfig;
            if(!!terminateLogicEnabled) {
                let question = terminateLogicEnabled.questionId;
                let answerIds = terminateLogicEnabled.answerIds;
                let _foundAnswers = this.state.answers.filter(item => item.questionId === question && answerIds.includes(item.answerId))
                isTerminate = _foundAnswers && _foundAnswers.length > 0 ? true : false;
            }
            if(!!forceLogicEnabled) {
            let sourceQuestion = forceLogicEnabled.sourceQuestionId;
            let sourceAnswerIds = forceLogicEnabled.sourceAnswerIds;
            let foundAnswers = this.state.answers.filter(item => item.questionId === sourceQuestion && sourceAnswerIds.includes(item.answerId))
            isRequired = foundAnswers && foundAnswers.length > 0 ? true : false;
            }
            if(this.validate(q.id, isRequired)){
              return true;
            } else {
              return false;
            }
          }) 
          isCompleted = !!isTerminate ?  isTerminate : ((this.state.activeStep + result.length -1) === this.state.questions.length - 2) ? true : false;
          let inValid = result.some((item) => item === false)
          if(!inValid) {
            this.setIsValid(true);
            this.setLoading(true);
            this.handleMatrixSubmit( isCompleted, (!!isCompleted) ? this.state.questions.length - 1 : result.length> 1 ? result.length + this.state.activeStep: this.state.activeStep+1, questionsList);
          } else {
            this.setIsValid(false);
          }
        } else {
            isRequired = this.state.questions[this.state.activeStep].required;
            let forceLogicEnabled = this.state.questions[this.state.activeStep].forceResponsesConfig;
            if(!!forceLogicEnabled) {
            let sourceQuestion = forceLogicEnabled.sourceQuestionId;
            let sourceAnswerIds = forceLogicEnabled.sourceAnswerIds;
            let foundAnswers = this.state.answers.filter(item => item.questionId === sourceQuestion && sourceAnswerIds.includes(item.answerId))
            isRequired = foundAnswers && foundAnswers.length > 0 ? true : false;
            }
          if (this.validate(questionId, isRequired)) {
            this.setIsValid(true);
            this.setLoading(true);
            this.handleSubmit(questionId, isCompleted);
          } else {
            this.setIsValid(false);
          }
        }
    };
    handleBack = () => {
        this.setIsValid(true);
        this.setState((prevState, props) => ({activeStep: this.getActiveStep(prevState.activeStep, false), ...prevState.items}))
    };

    handleChange(event, v, qId) {
        let value = event.target.value;
        let id = event.target.id;
        let type = event.target.type;
        type = (type == null || type === 'button') ? 'radio' : type;
        value = (value == null || type === 'select') ? v : value;
        let questionId = (type === 'textarea') ? this.getData(id, 0) : this.getData(value, 0);
        let answerId = (type === 'textarea') ? this.getData(id, 1) : this.getData(value, 1);
        let question = this.state.questions.find(q => q.id === questionId);

        let comments = '';
        let _answers = this.state.answers;

        if (type === 'radio' && questionId == null) {
            const index = _answers.findIndex((item => item.questionId === qId))
            index >= 0 && _answers.splice(index, 1);
            this.setState({ answers: _answers });
            return;
        }

        if (type === 'radio') {
            const index = _answers.findIndex((item => item.questionId === questionId))
            index >= 0 && _answers.splice(index, 1);
        }
        if (type === 'select' && questionId == null) {
            const index = _answers.findIndex((item => item.questionId === qId))
            index >= 0 && _answers.splice(index, 1);
            this.setState({ answers: _answers });
            return;
        }
        if (type === 'select') {
            const index = _answers.findIndex((item => item.questionId === questionId))
            index >= 0 && _answers.splice(index, 1);
        }
        if (type === 'textarea') {
            const index = _answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
            index >= 0 && _answers.splice(index, 1);
            comments = value;
        }
        if (type === 'checkbox' && event.target.checked === false) {
            const index = _answers.findIndex((item => item.questionId === questionId && item.answerId === answerId))
            index >= 0 && _answers.splice(index, 1);
            this.setState({ answers: _answers });
            return;
        }

        if(questionId === 1725 && answerId === 9319 && _answers.some(item => item.questionId === questionId && item.answerId !== 9319) ){
            let list = _answers.filter(item => item.questionId === 1725 && item.answerId !== 9319)
            list.forEach( l => {
                const index = _answers.findIndex((item => item.questionId === 1725 && item.answerId === l.answerId))
                index >= 0 && _answers.splice(index, 1);
            })
        }
        if(questionId === 1726 && answerId === 9324 && _answers.some(item => item.questionId === questionId && item.answerId !== 9324) ){
            let list = _answers.filter(item => item.questionId === 1726 && item.answerId !== 9324)
            list.forEach( l => {
                const index = _answers.findIndex((item => item.questionId === 1726 && item.answerId === l.answerId))
                index >= 0 && _answers.splice(index, 1);
            })
        }

        let _answer = { 'questionId': questionId, 'answerId': answerId, 'comments': comments }
        _answers.push(_answer);
        this.setState({ answers: _answers });
        if (!question.comments && !question.group && (question.type === "SMILEY" || question.type === "RADIO" || question.type === "STAR" || question.type === "NPS" )) {
            setTimeout(() => {  this.handleNext();  }, 300);
            if(!this.loading) {
                setTimeout(() => {  this.OnTransform(-100, 0); }, 200);
                setTimeout(() => {  this.OnTransform(0, 1); }, 1090);
            }
        }
    }
    render() {
        let banner = API_URL + "/api/survey/banner/" + (this.state.token ? this.state.token : this.state.surveyToken) + "?lang=" + this.state.lang;
        return (
            <>
            <Card>
                <CardMedia component="img" image={banner}></CardMedia>
                <CardActions>
                    <Box width="100%" display="flex" alignItems="left" justifyContent="left">
                        <TopBar surveyId={this.state.surveyId} token={this.state.token} lang={this.state.lang} ro={this.state.ro} pv={this.state.pv} languages={this.state.languages} />
                        {
                            this.state.status === 'VALID' && this.state.questions && this.state.questions.length > 0 &&
                            <ProgressMobileStepper ro={this.state.ro} getActiveStep={this.getActiveStep} isValid={this.state.isValid} loading={this.state.loading} setIsValid={this.setIsValid} opacity={this.state.opacity} translateX = {this.state.translateX}
                            varMap={this.state.varMap} handleMatrixNext={this.handleMatrixNext} handleBack={this.handleBack} setLoading={this.setLoading} validate={this.validate} handleSubmit={this.handleSubmit} handleNext={this.handleNext} activeStep={this.state.activeStep} setActiveStep={this.setActiveStep} handleMatrixSubmit={this.handleMatrixSubmit} getComments={this.getComments} isChecked={this.isChecked} handleChange={this.handleChange} answers={this.state.answers} questions={this.state.questions} logics={this.state.logics} matrixSurvey={this.state.matrixSurvey} />
                        }
                        {
                            this.state.status === 'EXPIRED' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                                {label(labels.get("survey_expired"))}
                            </Typography>
                        }
                        {
                            this.state.status === 'COMPLETED' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                                {label(labels.get("survey_completed"))}
                            </Typography>
                        }
                        {
                            this.state.status === 'NOTFOUND' && <Typography style={{ padding: '1em' }} color="error" variant="h5">
                                {label(labels.get("survey_notfound"))}
                            </Typography>
                        }
                    </Box>
                </CardActions>
            </Card>
            <CustomizedSnackbar
                open={this.state.snackBarOpen}
                handleClose={this.handleSnackBarClose}
                severity={this.state.snackBarSeverity}
                msg={this.state.snackBarMessage}
            />
            </>
        );
    }
}

export default Survey;