import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import TextArea from './TextArea';
import label from 'src/utils/label';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    },
    '& label.Mui-focused': {
      color: green[600],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: green[400],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: green[400],
      },
      '&:hover fieldset': {
        borderColor: green[400],
      },
      '&.Mui-focused fieldset': {
        borderColor: green[600],
      }
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const Checkboxes = ({ ro, isComments, getComments, isChecked, handleChange, questionId, answers }) => {
  answers = (questionId === 1739) ? answers.sort((a, b) => (a.score < b.score) ? -1 : 1) : answers;
  const answersList = answers.map((answer, index) => {
    if (isComments) {
      if (index < (answers.length - 1)) {
        return (
          <FormControlLabel
            key={answer.id}
            value={questionId + '_' + answer.id}
            control={<GreenCheckbox size="medium" />}
            label={label(answer.label)}
            onChange={handleChange}
            disabled={(isChecked(questionId, 9319) && answer.id !== 9319) || (isChecked(questionId, 9324) && answer.id !== 9324)}
            checked={isChecked(questionId, answer.id)}
          />
        )
      }
      if (index === (answers.length - 1)) {
        return <TextArea ro={ro} getComments={getComments} key={answer.id} handleChange={handleChange} questionId={questionId} answer={answer} />;
      }
    } else {
      return (
        <FormControlLabel
          key={answer.id}
          value={questionId + '_' + answer.id}
          control={<GreenCheckbox size="medium" />}
          label={label(answer.label)}
          onChange={handleChange}
          disabled={(isChecked(questionId, 9319) && answer.id !== 9319) || (isChecked(questionId, 9324) && answer.id !== 9324)}
          checked={isChecked(questionId, answer.id)}
        />
      )
    }
    return null;
  });

  return (
    <Box display="flex" alignItems="left" justifyContent="left" flexDirection="column">
      <FormControl disabled={JSON.parse(ro)} component="fieldset">
        {answersList}
      </FormControl>
    </Box>
  );
}

export default Checkboxes;